.container {
  height: 70px;
  width: 100%;
  max-width: 100%;
  background-color: #4280be;
  color: white;
  margin: 0;
  display: flex;
  align-items: center;
}
